
    .talron-progress-bar {

    }

    .label-block {
        margin-bottom: 7px;
    }

    .progress-bar-block {
        width: 100%;
    }

    .bar {
        position: relative;
        width: 100%;
        border-radius: 8px;
        background-color: #F1F1F1;
        height: 6px;
    }

    .progress {
        display: block;
        height: 100%;
        border-radius: 8px;

        background:  linear-gradient(to right, #856514 -76%, #A98019 113%);
        transition: width 200ms ease-in-out;
    }



    .progress-bar-labels {
        margin-top: 4px;
    }
