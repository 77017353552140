
.tr-scan-display-wrapper {
    z-index: 18;
    video, .drawingBuffer { // injected by Quagga, must be non-scoped
       //position: absolute;
       // left:0;
      //  top: 50%;
       // transform:  translateY(-50%);
       // max-width: 100%;
       // max-height: 100%;
    }

    .scan-display  {
      //  position: relative;
      .scandit.scandit-barcode-picker {
        border: 3px solid red;
        height: 100vh!important;
        max-height: 100vh!important;
      }

      .scandit-video.mirrored {
        height: 100vh!important;
        max-height: 100vh!important;
        object-fit: cover!important;
        top: 0;
        transform: none;
      }

      .scandit-camera-switcher, .scandit-torch-toggle{
        top: 100px;
      }
    }
    video {
     //   position: relative;
      //  border: 2px solid #ddd;
      //  box-shadow: 0 0 15px 3px rgba(0,0,0,0.5);
       // min-width: 100%;
       // background: transparent;

    }
}


