
    .feature-block {
        //this is enabled by vue 3.2. All praise the mighty Evan You
        cursor: v-bind('wrapperCursor');

        max-width: 104px;
        text-align: center;
        color: var(--global-primary-background-dark);
        font-weight: 600;
        line-height: normal;
        letter-spacing: normal;

        &__icon {
            display: flex;
            align-items: bottom;
            justify-content: center;
            height: 25px;
            width: 40px;
            justify-self: center;
            margin: 0 auto;
            margin-bottom: 5px;
        }
    }
